<script lang="ts">
    import moment from 'moment';

    import { inertia } from '@inertiajs/svelte';

    export let notifications;

    export let activeType;

    export let notificationTypes;

    export let Cookie;



    const icons = {
        all: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /></svg>`,

        messages: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" /></svg>`,

        likes: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" /></svg>`,

        subscriptions: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" /></svg>`,

        tips: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" /></svg>`,
    };

    // @ts-ignore
    const getIcons = (value) => {
        // @ts-ignore
        return icons[value] || '';
    };

    // @ts-ignore
    const notificationMessage = (notification) => {

        console.log(notification.announcement.title);

        switch (notification.type) {
            case 'tip':
                return `${notification.transaction.sender.name} sent you a tip of ${notification.transaction.amount} ${notification.transaction.currency}.`;
            case 'reaction':
                if (notification.post_id) {
                    return `${notification.from_user.name} liked your <a href="/posts/${notification.post.id}" target="_blank">post</a>.`;
                }
                if (notification.post_comment_id) {
                    return `${notification.postComment.author.name} liked your comment`;
                }
                break;
            case 'new-comment':
                return `${notification.from_user.name} added a new comment on your <a href="/posts/${notification.post_id}/${notification.post.user.username}" target="_blank">post</a>.`;
            case 'new-subscription':
                return 'A new user subscribed to your profile.';
            case 'withdrawal-action':
                return `Withdrawal processed ${notification.withdrawal.amount} ${notification.withdrawal.currency}.`;
            case 'message':
                return `Sent you a message${notification.userMessage?.message ? `: ${notification.userMessage?.message}` : ''}.`;
            case 'new-message':
                return `Sent you a message ${notification.userMessage?.message ? `: ${notification.userMessage?.message}` : ''}.`;
            case 'expiring-stream':
                return 'Your live streaming is about to end in 30 minutes. You can start another one afterwards.';
            case 'ppv_unlock':
                return `Someone unlocked your ${notification.PPVUnlockType}.`;
            case 'new-announcement':
                return `
                <a href="/announcements/${notification.announcement_id}" target="_blank">${notification.announcement.title}</a>`;
            default:
                return `You have a new notification`;
        }
    };
</script>

<div class="row">
    <div class="col-12 min-vh-100 border-right pt-4 sm:pr-0">
        <div class="border-bottom px-3 pb-4">
            <h5 class="text-truncate mb-0 font-bold {Cookie}">
                {'Notifications'}
            </h5>
        </div>
        <div class="mt-3">
            <!-- <div class="d-none d-md-block">
                @include('elements.notifications.notifications-menu', ['variant'
                => 'desktop'])
            </div>
            <div class="notifications-menu-mobile d-block d-md-none mt-3">
                @include('elements.notifications.notifications-menu', ['variant'
                => 'mobile'])
            </div> -->

            <nav class="-mb-px flex" aria-label="Tabs">
                <a
                    id="notifications-tabs"
                    href="{'/my/notifications'}"
                    use:inertia
                    tabindex="0"
                    role="button"
                    class="{`group flex w-1/5 items-center justify-center border-b-2 border-l-0 border-r-0 border-t-0 border-solid px-1 py-4 text-base font-semibold capitalize ${activeType === null ? 'border-500 text-bmn-500 hover:text-bmn-500' : 'border-gray-300 text-gray-300 hover:border-gray-400 hover:text-gray-400'}hover:border-gray-600 hover:text-gray-600 dark:hover:border-gray-400 dark:hover:text-gray-400`}"
                >
                    {@html icons.all}&nbsp; All
                </a>
                {#each notificationTypes as item}
                    <a
                        href="{item !== 'all' ? `/my/notifications/${item}` : '/my/notifications'}"
                        use:inertia
                        tabindex="0"
                        role="button"
                        class="{`group flex w-1/5 items-center justify-center border-b-2 border-l-0 border-r-0 border-t-0 border-solid px-1 py-4 text-base font-semibold capitalize ${activeType === item || (item === 'all' && !activeType) ? 'border-500 text-bmn-500 hover:text-bmn-500' : 'border-gray-300 text-gray-300 hover:border-gray-400 hover:text-gray-400'}hover:border-gray-600 hover:text-gray-600 dark:hover:border-gray-400 dark:hover:text-gray-400`}"
                    >
                        {@html getIcons(item)}&nbsp;
                        {item}
                    </a>
                {/each}
            </nav>
        </div>

        {#if notifications.data.length > 0}
            <div class="notification-main pt-4">
                {#each notifications.data as notification}
                    <div class="notification-box pl-md-4 py-2 pl-3 {!notification.read ? 'unread' : ''}">
                        <div class="d-flex flex-row-no-rtl my-1">
                            {#if notification.from_user}
                                <div class="flex items-center">
                                    <img
                                        class="rounded-circle avatar w-3/5"
                                        src="{notification.from_user.avatar}"
                                        alt="{notification.from_user.username}"
                                    />
                                </div>
                            {/if}
                            <div class="w-100">
                                <div class="d-flex flex-row-no-rtl justify-content-between">
                                    {#if notification.from_user}
                                        <div class="d-flex flex-column">
                                            <h6 class="d-flex m-0 p-0 font-bold">
                                                <a
                                                    href="{`/${notification.from_user.username}`}"
                                                    use:inertia
                                                    class="text-dark-r">{notification.from_user.name}</a
                                                >
                                            </h6>
                                            <div class="font-bold">
                                                <a
                                                    href="{`/${notification.from_user.username}`}"
                                                    use:inertia
                                                    class="text-muted"
                                                >
                                                    @{notification.from_user.username}
                                                </a>
                                            </div>
                                        </div>
                                    {/if}
                                    <div class="position-absolute separator"></div>
                                </div>
                                <div>
                                    <div class="text-break my-1 pr-3 {!notification.read ? 'font-bold' : ''}">
                                        {@html notificationMessage(notification)}
                                    </div>
                                    <div class="d-flex text-muted">
                                        {moment(notification.created_at).fromNow()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2" />
                {/each}
            </div>
        {:else}
            <div class="no-notification mt-3">
                <div class="flex flex-col items-center justify-center py-4 text-center">
                    <div class="mb-2 size-20">
                        <!-- {@html NoNotifications} -->
                        <!-- <img src="{Notifications}" alt="" srcset="" /> -->

                        
                    </div>
                    <h2 class="text-2xl font-extrabold">No Notifications yet</h2>
                    <p>Start using our features to get something going here</p>
                </div>
            </div>
        {/if}
    </div>
</div>
